.dots {
  animation-name: backAndForth;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.dots:nth-child(1) {
  animation-delay: 0.5s;
  margin-left: 5px;
}
.dots:nth-child(2) {
  animation-delay: 1s;
}
.dots:nth-child(3) {
  animation-delay: 1.5s;
}
@keyframes backAndForth {
  0% {
    margin-top: 0 ;
  }
  20% {
    margin-top: -4px ;
  }
  70% {
    margin-top: 0 ;
  }
}
